import React from 'react'
import { Link } from 'gatsby'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import success from "../images/success.svg"

const Success = () => {
    return (
        <Layout>
            <Head title="Talebin alındı, teşekkürler!" />
            <div className="row blog-index">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Teşekkürler! <span role="img" aria-label="emoji">🐳</span></h1>
                <p>Çember ahalisine hoş geldin! En kısa zamanda iletişime geçeceğiz, beklemede kal! Henüz indirmediysen 
                  Çember'i App Store veya Play Store'dan indirebilir, hemen kullanmaya başlayabilirsin.
                </p>
                <h5><Link to="/" className="cta-blue-button">Anasayfaya Dön</Link></h5>
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={success} alt="Success"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default Success